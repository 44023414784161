import { EventTargetData } from './events';

export const waitToUpdateMenuState = (done: () => any): void => {
    window.setTimeout(() => {
        done();
    }, 0);
};

export const isDuplicateClickEvent = (
    clickedElementsList: string[],
    elName: string,
    eventType: EventTargetData['eventType']
) => {
    let touchedElementIndex = clickedElementsList.indexOf(elName);
    if (touchedElementIndex > -1) {
        clickedElementsList.splice(touchedElementIndex, 1);
        return eventType === 'click';
    } else {
        if (eventType === 'touchend') {
            clickedElementsList.push(elName);
        }
        return false;
    }
};
