import { Store, StoreAvailability } from '../types/store';
import OmniStores from './OmniStores';

export const SUBSCRIBE_TO_STORE_UPDATES_CUSTOM_EVENT = 'kmxSubscribeToMyStoreModuleUpdates';
export const SET_STORE_CUSTOM_EVENT = 'kmxUpdateMyStoreModuleByStore';
export const SET_STORE_BY_ID_CUSTOM_EVENT = 'kmxUpdateMyStoreModuleById';
export const SET_STORE_BY_ID_ONLOAD_CUSTOM_EVENT = 'kmxUpdateMyStoreModuleByIdOnLoad';
export const OPEN_STORE_SELECTION_MODAL_EVENT = 'kmxOpenStoreSelectionModal';
export const CLOSE_STORE_SELECTION_MODAL_EVENT = 'kmxCloseStoreSelectionModal';
export const ENABLE_SEARCH_BAR_TEST = 'kmxEnableSearchBarTest';
export const SET_COGNITIVE_SEARCH_EVENT = 'kmxEnableCognitiveSearch';
export const ENABLE_SEARCH_BAR_DISPLAY_TEST = 'kmxEnableSearchBarDisplayPromptTest';

export const EMPTY_STORE: Store = {
    Id: '',
    Name: '',
    PhoneNumbers: [],
    Hours: [],
    PhoneHours: [],
    ServiceHours: [],
    Latitude: 0,
    Longitude: 0,
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    MailingCity: '',
    StateAbbreviation: '',
    ZipCode: '',
    HolidayHours: [],
    ActiveStatus: '',
    AlertMessage: '',
    Capabilities: [],
    DistanceInMiles: 0,

    // `legacy` fields as of 12.2021:
    Categories: [],
    DisplayName: '',
    GeneralTelephoneNumbers: [],
    IsTemporarilyClosed: false,
    IsSaveable: true,
    IsCarBuyingCenter: false,
    Links: [],
    LocationDisplay:'',
    SalesTelephoneNumbers: [],
    ShowroomHours: [],
    State: '',
    Status: '',
    StatusAlert: '',
    Subtitle: '',
    TelephoneHours: [],
    TelephoneNumber: '',
    TelephoneNumbers: [],
    TollFreePhoneNumber: ''
};

export const DEFAULT_STORE_AVAILABILITY: StoreAvailability = {
    Id: '',
    IsOpen: false,
    CurrentHours:[],
    Holiday: [],
    Alert: { status: '', message: '', storeId: '' }
}

export const CUSTOMER_HUB_STORES_LIST = OmniStores.CUSTOMER_HUB_STORES_LIST;
export const DELIVERY_STORES_LIST: string[] = OmniStores.DELIVERY_STORES_LIST;
export const EXPEDITED_PICKUP_STORES_LIST = OmniStores.EXPEDITED_PICKUP_STORES_LIST;
export const AFTER_SALE_DELIVERY_STORES_LIST: string[] = OmniStores.AFTER_SALE_DELIVERY_STORES_LIST;
export const CURBSIDE_STORES_LIST = OmniStores.CURBSIDE_STORES_LIST;

export const GRAND_OPENING_ACTIVE_STATUS = 'Grand Opening';

export const SEARCH_BAR_TEST = 'SearchBar';
export const COGNITIVE_SEARCH_TEST = 'CognitiveSearch';
