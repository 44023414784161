import activeTests from './activeTests';
import * as constants from '../constants';
import { content } from '../content';

function onEventFired(event: any) {
    activeTests.activateTest(constants.ENABLE_SEARCH_BAR_DISPLAY_TEST);

    const input = document.getElementById(content.HEADER_SEARCH.input.id);
    if (input) {
        const group = event.detail.group;
        if (group === 'B') {
            input.setAttribute('placeholder', "Describe what you're looking for");
            input.setAttribute('aria-placeholder', "Describe what you're looking for");
        } else if (group === 'C') {
            input.setAttribute('placeholder', 'What kind of car are you looking for?');
            input.setAttribute('aria-placeholder', 'What kind of car are you looking for?');
        } else if (group === 'D') {
            input.setAttribute('placeholder', "Tell us what you're looking for");
            input.setAttribute('aria-placeholder', "Tell us what you're looking for");
        }
    }
}

export function subscribe() {
    // Note: Fired by adobe target test 'SF Search Bar Test'.
    document.documentElement.addEventListener(constants.ENABLE_SEARCH_BAR_DISPLAY_TEST, (event => {
        onEventFired(event);
    }) as EventListener);
}
