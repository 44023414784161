import * as searchApi from './searchApi';
import * as utils from './utils';
import * as constants from '../../constants';
import SearchSuggestionsMenu from './SearchSuggestionsMenu';
import { content } from '../../content';
import { getQueryStringValue } from '../../utils/environment';
import activeTests from '../../targetTests/activeTests';
import { IPayload } from '../../types/analytics';
import { ANALYTICS_CONSTANTS, track } from '../../utils/analytics';

export interface IInventorySearch {
    attachSetCognitiveSearchListener(): void;
    handleInput(event: Event): void;
    handleSubmit(event: Event): void;
    handleInputKeydown(event: KeyboardEvent): void;
    handleMenuKeydown(event: KeyboardEvent): void;
    handleClickOutside(): void;
}

export default class InventorySearch implements IInventorySearch {
    private _menu: SearchSuggestionsMenu;
    private ineligibleTerm: string = '';

    constructor() {
        this.attachSetCognitiveSearchListener();
        this._menu = new SearchSuggestionsMenu(
            () => {
                this.setSearchBarActiveState(true);
            },
            () => {
                this.setSearchBarActiveState(false);
            }
        );
        this.preserveSearchBarPriorContents();

        const searchButton = document.querySelector('#header-search-button') as HTMLElement;
        if (searchButton != null) {
            searchButton.onclick = (e: MouseEvent) => this.handleSearchClick(e);
        }
    }

    public attachSetCognitiveSearchListener(): void {
        window.addEventListener(constants.SET_COGNITIVE_SEARCH_EVENT, () => {
            activeTests.activateTest(constants.COGNITIVE_SEARCH_TEST);
        });
    }

    handleInput(event: Event): void {
        const targetEl = event.target as HTMLInputElement;
        utils.debounce(() => {
            this.ineligibleTerm = '';
            if (targetEl.value || targetEl === document.activeElement) {
                let params = '';
                if (targetEl.value && activeTests.determineIsActive(constants.COGNITIVE_SEARCH_TEST)) {
                    params = 'useCognitiveSearch=true';
                }

                if (!activeTests.determineIsActive(constants.SEARCH_BAR_TEST)) {
                    searchApi.getSearchSuggestions(targetEl.value, params, searchSuggestionsList => {
                        if (searchSuggestionsList) {
                            if(searchSuggestionsList.ineligibleTermsAlternates && searchSuggestionsList.ineligibleTermsAlternates.length > 0) {
                                this.ineligibleTerm = targetEl.value;
                            }
                            this._menu.render(searchSuggestionsList);
                        }
                    });
                }
            } else {
                this._menu.render({
                    freeTextSearches: [],
                    length: 0,
                    savedSearches: [],
                    recentSearches: [],
                    ineligibleTermsAlternates: []
                });
            }
        }, 300);
    }

    handleSubmit(event: Event): void {
        if (event) {
            event.preventDefault();

            const input = document.querySelector(`#${content.HEADER_SEARCH.input.id}`) as HTMLInputElement;

            // Prevent submit if the current text in the input has been marked as ineligible.
            const isIneligibleTerm = this.checkIneligibleTerm(input);
            if (input && !isIneligibleTerm) {
                utils.updateSearchId();
                const trimmedInputValue = input.value.trim();
                if (utils.isStockNumber(trimmedInputValue)) {
                    utils.redirectToCarPage(trimmedInputValue);
                } else {
                    const formEl = event.target as HTMLFormElement;
                    if (formEl) {
                        formEl.submit();
                    }
                }
            }
        }
    }

    handleInputKeydown(event: KeyboardEvent): void {
        this._menu.handleInputKeydown(event);
    }

    handleMenuKeydown(event: KeyboardEvent): void {
        this._menu.handleMenuKeydown(event);
    }

    handleClickOutside(): void {
        this._menu.handleClickOutside();
    }

    handleSearchClick(event: MouseEvent): void {
        //HERE ADD
        const payload = {
            "event": ANALYTICS_CONSTANTS.EVENTS.searchInitiated,
            "linkDetails" : {
                "name": "Search Bar:header-search-bar-form",
                "position": "Free-Text Search Box"
            },
            "search" : {
                "initiationMethod" : "Homepage|Free-Text Search Box|Search Bar:header-search-bar-form"
            }
        } as IPayload;
        track(payload);
        const input = document.querySelector(`#${content.HEADER_SEARCH.input.id}`) as HTMLInputElement;
        const isIneligibleTerm = this.checkIneligibleTerm(input);
        if (input && input.value && isIneligibleTerm) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private checkIneligibleTerm(input: HTMLInputElement) : boolean {
        if (!this.ineligibleTerm) {
            return false;
        }

        const isIneligibleTerm = input.value === this.ineligibleTerm;
        if (!isIneligibleTerm) {
            // The text box no longer contains the text marked as ineligible. Clear it.
            this.ineligibleTerm = '';
        }

        return isIneligibleTerm;
    }

    private setSearchBarActiveState(active: boolean): void {
        const headerSearchBar = document.querySelector(`#${content.HEADER.id} #${content.HEADER_SEARCH.id}`);

        if (headerSearchBar) {
            if (active) {
                headerSearchBar.classList.add(content.HEADER_SEARCH.activeClass);
            } else {
                headerSearchBar.classList.remove(content.HEADER_SEARCH.activeClass);
            }
        }
    }

    private preserveSearchBarPriorContents() {
        // The search param will contain the last value the user searched on.
        const searchQueryStringValue = getQueryStringValue('search');

        const input = document.querySelector(`#${content.HEADER_SEARCH.input.id}`) as HTMLInputElement;
        if (input) {
            input.value = searchQueryStringValue;
        }
    }
}
